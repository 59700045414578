.quotes-wrapper {
  .filters {
    .languages {
      max-width: 500px;
      width: 100%;
    }

    .search-bar {
      max-width: 500px;
      width: 100%;
    }
  }

  .actions {
    background-color: #2c2d30;
  }

  .quotes-card-warpper {
    background-color: #2c2d30;

    .discription {
      max-width: 400px;
      ul li {
        list-style: disc;
      }

      .card-selector {
        .label {
          max-width: 100px;
          width: 100%;
          margin-right: 10px;
        }
      }
    }
  }
}
