.layout {
  padding: 15px;
  background-color: black;
  position: relative;

  .wrapper {
    height: calc(100vh - 30px);
    display: grid;
    grid-template-columns: 280px 1fr;

    .sidebar {
      padding: 10px;
      background-color: #2c2d30;
      border-radius: 15px;
      margin-right: 15px;

      .nav-link {
        height: 40px;
        background-color: #292929;
        color: white;
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        margin: 7px 0;
        padding: 0 20px;
        text-decoration: none;
        font-size: 20px;
      }
    }

    .mobile-sidebar {
      border-radius: 0;
      width: 280px;
      position: fixed;
      height: 100%;
      top: 0;
      left: -100%;
      transition: 850ms;
      background-color: #444a5b;
      z-index: 1;
    }

    .mobile-sidebar.active {
      left: 0;
      transition: 350ms;
    }

    .main-content {
      overflow-y: hidden;
      color: white;
      width: 100%;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #2e2e32;
        color: white;
        padding: 5px 25px;
        border-radius: 5px;
        margin-bottom: 10px;
        font-size: 20px;

        button {
          font-size: 16px;
          background-color: #52ab52;
          color: #212024;
          width: 100%;
          max-width: 190px;
          height: 36px;
          border-radius: 5px;
          border: none;
          cursor: pointer;
        }
      }

      /* width */
      ::-webkit-scrollbar {
        width: 7px;
        border-radius: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 5px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #888;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .layout {
    .wrapper {
      display: flex;
    }
  }
}
