.btn-success {
  background-color: #52ab52 !important;
}
.btn-warning {
  background-color: #eea339 !important;
}
.btn-danger {
  background-color: #d24743 !important;
}
.btn-info {
  background-color: #3074ae !important;
}
