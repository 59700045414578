.published-wallpaper-wrapper {
  .filters {
    .languages {
      max-width: 500px;
      width: 100%;
    }

    .search-bar {
      max-width: 500px;
      width: 100%;
    }
  }

  .actions {
    background-color: #2c2d30;
  }

  .wallpaper-card-warpper {
    background-color: #2c2d30;

    .image-container {
      width: 200px;
      img {
        width: 100%;
      }
    }

    .discription {
      ul li {
        list-style: disc;
      }

      .card-selector {
        .label {
          max-width: 72px;
          width: 100%;
        }
      }
    }
  }
}
